export default class ShareSheet {
  constructor(options = {}) {
    this.utm = options.utm;
    this.area = options.area;
    this.shareUrl = options.shareUrl || window.location.href;
    this.outlets = options.outlets || ['twitter', 'facebook', 'linkedin', 'pinterest', 'email'];
    this.attach = options.attach || false;
    this.demo = options.demo || false;
    this.trackCollectionShare = options.trackCollectionShare || null;
    this.container = options.container || document.querySelector(options.selector);
    this.delegateEvents = options.delegateEvents || false;
    this.body = {
      raw: options.body,
      encoded: encodeURIComponent(options.body),
    };

    if (options.imageUrl) {
      this.imageUrl = {
        raw: options.imageUrl,
        encoded: encodeURIComponent(options.imageUrl),
      };
    }

    this.bodies = {};
    if (options.bodies) {
      Object.keys(options.bodies).forEach((key) => {
        this.bodies[key] = {
          raw: options.bodies[key],
          encoded: encodeURIComponent(options.bodies[key]),
        };
      });
    }

    this.clipboardLink = options.clipboardLink;

    this.attachShareSheet();
  }

  attachShareSheet() {
    if (this.attach.twitter && this.outlets.indexOf('twitter') > -1) {
      this.attachAction('twitter');
    }

    if (this.attach.facebook && this.outlets.indexOf('facebook') > -1) {
      this.attachAction('facebook');
    }

    if (this.attach.linkedin && this.outlets.indexOf('linkedin') > -1) {
      this.attachAction('linkedin');
    }

    if (this.attach.pinterest && this.outlets.indexOf('pinterest') > -1 && this.imageUrl) {
      // Pinterest needs a specific image URL to be given to its widget, which
      // is easy to do for shots but less easy to infer (currently) for stories
      this.attachAction('pinterest');
    }

    if (this.attach.email && this.outlets.indexOf('email') > -1) {
      this.attachAction('email');
    }

    if (this.attach.clipboard && this.outlets.indexOf('clipboard') > -1) {
      this.attachAction('clipboard');
    }
  }

  createAction(named, label) {
    const action = document.createElement('a');
    const linkText = document.createTextNode(named);
    action.appendChild(linkText);
    action.title = label;
    action.class = `share-${named}`;
    action.href = '#';

    action.addEventListener('click', this.shareTo.bind(this, named));

    return action;
  }

  attachAction(named) {
    // Attach click event using event delegation for markup generated by Vue apps
    if (this.delegateEvents) {
      if (this[`attach${named}`]) {
        this.container.removeEventListener('click', this.attach[named]);
      }

      this[`attach${named}`] = (e) => {
        this.shareTo(named, e);
      };

      // this.container.on('click', this.attach[named], this[`attach${named}`]);
      document.addEventListener('click', (e) => {
        const className = this.attach[named].substring(1);

        if (e.target.classList.contains(className)) {
          this[`attach${named}`].call(e.target, e);
        } else {
          const parentElement = e.target.closest(this.attach[named]);
          if (parentElement) {
            this[`attach${named}`].call(parentElement, e);
          }
        }
      }, true);
    } else {
      // Default to add click listener the old way.
      const selector = this.container.querySelector(this.attach[named]);
      selector.addEventListener('click', this.shareTo.bind(this, named));
    }
  }

  shareTo(named, event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (this.demo) {
      this.demo();
      return;
    }

    let shareUrl = null;
    let windowParams = null;

    switch (named) {
      case 'twitter': {
        const body = this.bodies.twitter ? this.bodies.twitter.encoded : this.body.encoded;
        shareUrl = `https://twitter.com/intent/tweet?url=${this.url('Twitter', event.currentTarget).encoded}&text=${body}`;
        windowParams = 'status=0,toolbar=0,height=250,width=600';
        break;
      }
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${this.url('Facebook', event.currentTarget).encoded}`;
        windowParams = 'status=0,toolbar=0,height=350,width=600';
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${this.body.encoded}&summary=${this.body.encoded}&source=Dribbble`;
        windowParams = 'status=0,toolbar-0,height=350,width=600';
        break;
      case 'pinterest':
        shareUrl = `https://pinterest.com/pin/create/button/?url=${this.url('Pinterest', event.currentTarget).encoded}&description=${this.body.encoded}&media=${this.imageUrl.encoded}`;
        windowParams = 'status=0,toolbar=0,height=260,width=600';
        break;
      case 'email':
        shareUrl = `mailto:?&body=${this.body.encoded}${encodeURIComponent('\n\n')}${this.url('Email', event.currentTarget).encoded}`;
        break;
      case 'clipboard': {
        const linkEl = document.createElement('input');
        linkEl.value = this.url('Clipboard', event.currentTarget).raw;
        linkEl.style.position = 'fixed';
        linkEl.style.top = '0px';
        linkEl.style.left = '0px';
        linkEl.style.zIndex = '-1';

        document.body.appendChild(linkEl);

        linkEl.focus();
        linkEl.select();

        if (document.execCommand('Copy')) {
          const message = 'A link to this page has been copied to your clipboard!';
          if (Dribbble.EventBus) {
            Dribbble.EventBus.$emit('siteNotifications:add', {
              title: 'Link Copied',
              message,
              type: 'success',
              id: (`clipboard-copy-success${new Date().getTime()}`),
            });
          } else {
            alert(message);
          }
        } else {
          const messageText = 'It looks like we couldn\'t copy this link. Please right click on the button and click "Copy Link Address".';
          if (Dribbble.EventBus) {
            Dribbble.EventBus.$emit('siteNotifications:add', {
              title: 'Error',
              messageText,
              id: (`clipboard-copy-success${new Date().getTime()}`),
            });
          } else {
            alert(messageText);
          }
        }

        document.body.removeChild(linkEl);
        break;
      }
      default:
        console.warn(`${named} does not have a handler`);
    }

    if (!shareUrl) return;

    if (windowParams) {
      window.open(shareUrl, '', windowParams);
    } else {
      window.location.href = shareUrl;
    }
  }

  url(source, srcElement) {
    let currentUrl = this.shareUrl;

    if (this.utm) {
      let utmSource;
      let utmCampaign;

      if (srcElement && srcElement.dataset && srcElement.dataset.shareSource) {
        utmSource = srcElement.dataset.shareSource;
      } else {
        utmSource = `${source}_${this.utm.source}`;
      }

      if (srcElement && srcElement.dataset && srcElement.dataset.shareCampaign) {
        utmCampaign = srcElement.dataset.shareCampaign;
      } else {
        utmCampaign = this.utm.campaign;
      }

      currentUrl = Dribbble.Url.addParams(currentUrl, [
        ['utm_source', utmSource],
        ['utm_campaign', utmCampaign],
        ['utm_content', this.utm.content],
        ['utm_medium', 'Social_Share'],
      ]);
    }

    return {
      raw: currentUrl,
      encoded: encodeURIComponent(currentUrl),
    };
  }
}
